var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-tabs', {
    model: {
      value: _vm.currentTab,
      callback: function callback($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ArchiveIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Detail")])];
      },
      proxy: true
    }])
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "xl",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.tutupModal();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori Paket",
      "label-for": "kategori"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optPaket
    },
    model: {
      value: _vm.ujianDataId.category_paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "category_paket_id", $$v);
      },
      expression: "ujianDataId.category_paket_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian",
      "label-for": "nama-paket"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Ujian",
      "name": "nama-paket"
    },
    model: {
      value: _vm.ujianDataId.name,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "name", $$v);
      },
      expression: "ujianDataId.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Ujian (singkat)",
      "label-for": "harga"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "shortname",
      "placeholder": "shortname"
    },
    model: {
      value: _vm.ujianDataId.shortname,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "shortname", $$v);
      },
      expression: "ujianDataId.shortname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Acak Soal",
      "label-for": "acak"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optquestion
    },
    model: {
      value: _vm.ujianDataId.shuffle_question,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "shuffle_question", $$v);
      },
      expression: "ujianDataId.shuffle_question"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tampilkan Hasil",
      "label-for": "show_result"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optresult
    },
    model: {
      value: _vm.ujianDataId.show_result,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "show_result", $$v);
      },
      expression: "ujianDataId.show_result"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instruksi",
      "label-for": "instruksi"
    }
  }, [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.ujianDataId.instruction,
      callback: function callback($$v) {
        _vm.$set(_vm.ujianDataId, "instruction", $$v);
      },
      expression: "ujianDataId.instruction"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9",
      "xl": "9"
    }
  }, [_c('table', {
    staticClass: "mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Nama Ujian")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" : " + _vm._s(_vm.ujianDataId.name + " - (" + _vm.ujianDataId.shortname + ")") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Kategori Paket")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" : " + _vm._s(_vm.ujianDataId.category_paket != null ? _vm.ujianDataId.category_paket.name : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Acak Soal")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" : " + _vm._s(_vm.ujianDataId.shuffle_question == 1 ? "Ya" : "Tidak") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tampilkan Hasil")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" : " + _vm._s(_vm.ujianDataId.show_result == 1 ? "Ya" : "Tidak") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Instruksi Ujian")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" : "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.ujianDataId.instruction)
    }
  })])])])]), _c('b-col', {
    attrs: {
      "cols": "3",
      "xl": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.ModalUbah(_vm.ujianDataId);
      }
    }
  }, [_vm._v("Edit")])], 1), _c('hr')], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Soal Ujian")])];
      },
      proxy: true
    }])
  }, [_c('soal-ujian', {
    attrs: {
      "ujianDataId": _vm.ujianDataId
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }