var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Bank Soal")])])], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Paket")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optPaket
    },
    on: {
      "input": function input($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filterPaket,
      callback: function callback($$v) {
        _vm.filterPaket = $$v;
      },
      expression: "filterPaket"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('label', [_vm._v("Pilih Tipe")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "value",
      "clearable": true,
      "options": _vm.optTipe
    },
    on: {
      "input": function input($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filterTipe,
      callback: function callback($$v) {
        _vm.filterTipe = $$v;
      },
      expression: "filterTipe"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';

        _vm.getData();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "striped": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "fields": _vm.fields,
      "items": _vm.soalData
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(selected)",
      fn: function fn(_ref) {
        var rowSelected = _ref.rowSelected;
        return [rowSelected ? [_c('i', {
          staticClass: "feather icon-disc primary"
        })] : [_c('i', {
          staticClass: "feather icon-circle"
        })]];
      }
    }, {
      key: "cell(show_details)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('b-row', {}, [_c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "4"
          }
        }, [_c('strong', [_vm._v("Tipe Soal : ")]), _vm._v(_vm._s(row.item.type) + " ")]), _c('b-col', {
          staticClass: "mb-1",
          attrs: {
            "md": "4"
          }
        }, [_c('strong', [_vm._v("Bobot : ")]), _vm._v(_vm._s(row.item.bobot) + " ")])], 1), _c('b-row', [_c('b-col', {
          staticClass: "mb-1"
        }, [_c('strong', [_vm._v("Pertanyaan : ")]), _c('quill-editor', {
          attrs: {
            "id": row.item.id,
            "options": _vm.editor_options
          },
          model: {
            value: row.item.content,
            callback: function callback($$v) {
              _vm.$set(row.item, "content", $$v);
            },
            expression: "row.item.content"
          }
        })], 1)], 1), _c('b-row', [row.item.type == 'pg' || row.item.type == 'multiple_choice' || row.item.type == 'benar_salah' ? _c('div', [_c('b-tabs', {
          staticClass: "mt-2"
        }, _vm._l(row.item.options, function (data, index) {
          return _c('div', {
            key: index
          }, [_c('b-tab', {
            scopedSlots: _vm._u([{
              key: "title",
              fn: function fn() {
                return [_c('div', [index + 1 == 1 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
                  staticClass: "font-weight-bold"
                }, [_vm._v("Opsi. E")]) : _vm._e()])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
            attrs: {
              "id": "Konten",
              "options": _vm.editor_options
            },
            model: {
              value: data.content,
              callback: function callback($$v) {
                _vm.$set(data, "content", $$v);
              },
              expression: "data.content"
            }
          })], 1)], 1), _c('b-row', [row.item.type == 'multiple_choice' || row.item.type == 'benar_salah' ? _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [index + 1 == 1 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
            staticClass: "font-weight-bold mt-2"
          }, [_vm._v("Jawaban Opsi. E")]) : _vm._e(), row.item.type == 'multiple_choice' ? _c('b-form-checkbox', {
            staticClass: "custom-control-primary",
            attrs: {
              "value": "1"
            },
            model: {
              value: data.is_correct,
              callback: function callback($$v) {
                _vm.$set(data, "is_correct", $$v);
              },
              expression: "data.is_correct"
            }
          }, [_vm._v(" Kunci Jawaban ")]) : _vm._e(), row.item.type == 'benar_salah' ? _c('b-form-radio-group', {
            attrs: {
              "options": _vm.bsSoal,
              "name": "radios-stacked",
              "stacked": ""
            },
            model: {
              value: data.is_true_or_false,
              callback: function callback($$v) {
                _vm.$set(data, "is_true_or_false", $$v);
              },
              expression: "data.is_true_or_false"
            }
          }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1);
        }), 0)], 1) : _vm._e()]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" Hide Details ")])], 1)];
      }
    }, {
      key: "cell(tgl)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDate(data.item.info_start)) + " s/d " + _vm._s(_vm.humanDate(data.item.info_end)) + " ")];
      }
    }, {
      key: "cell(check)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.select_soal,
            callback: function callback($$v) {
              _vm.select_soal = $$v;
            },
            expression: "select_soal"
          }
        })];
      }
    }, {
      key: "cell(max_quota)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.max_quota)) + " ")];
      }
    }, {
      key: "cell(color)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": data.item.color
          }
        }, [_vm._v(" " + _vm._s(data.item.color) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.type == "pg" ? "PG" : data.item.type == "multiple_choice" ? "Multiple Choice" : data.item.type == "benar_salah" ? "Benar Salah" : "Pauli") + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Soal yang sebelumnya dipilih"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "sm",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2,
      "fields": _vm.fields2,
      "items": _vm.soalsebelum
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.type == "pg" ? "PG" : data.item.type == "multiple_choice" ? "Multiple Choice" : data.item.type == "benar_salah" ? "Benar Salah" : "Pauli") + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows2,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function callback($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Soal yang sekarang"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "sm",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2,
      "fields": _vm.fields2,
      "items": _vm.pilih
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.type == "pg" ? "PG" : data.item.type == "multiple_choice" ? "Multiple Choice" : data.item.type == "benar_salah" ? "Benar Salah" : "Pauli") + " ")];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows2,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function callback($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-start mt-1"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.update($event);
      }
    }
  }, [_vm._v(" Simpan")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        this.pilih = null;
      }
    }
  }, [_vm._v(" Reset")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }